import React from "react"
import { Link } from "gatsby"
import Carousel from "./Carousel"
import { globalHistory } from "@reach/router"

const SmallCards = ({ cards, large=false, customPath }) => {
  const currentPath = globalHistory.location.pathname

  return <section className={`${large && "large"} features section text-center`}>
        <div className="features-wrap">
          {
            cards.map(card => {
              return <div className="feature ">
                <div className="feature-inner">
                  <div className="feature-icon">
                    {card.carousel && card.carousel.length ? <Carousel images={card.carousel}/> :
                      <div
                        style={{
                          backgroundImage: `url(${card.image})`,
                        }}
                      />
                    }
                  </div>
                  <h4 className="feature-title">{card.title}</h4>
                  <p className="text-sm">
                    {card.subtitle}
                  </p>
                  <Link
                    className="button button-primary button-block cta"
                    to={(customPath || currentPath) + "/" + card.url}
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            })
          }


    </div>
  </section>
}
export default SmallCards
