import React from "react"
import { graphql } from "gatsby"
import SmallCards from "../../../components/SmallCards"
import Layout from "../../../components/Layout"
export default function SmallCardPage({ data, showFooter, noContainer, large, showHeader, darkMode }) {
  const { patients, donors, employees } = data
  return (
    <Layout showCta={false} showFooter={false} noContainer={noContainer} darkMode={darkMode} showHeader={showHeader} title={"All Organizations"}>
      <SmallCards customPath="/organizations/orgs/patients" cards={patients.frontmatter.card} large={large}/>
      <SmallCards customPath="/organizations/orgs/donors" cards={donors.frontmatter.card} large={large}/>
      <SmallCards customPath="/organizations/orgs/employees" cards={employees.frontmatter.card} large={large}/>
    </Layout>
  )
}
export const query = graphql`
  query getAllData {
     patients: markdownRemark(frontmatter: {url: {eq: "patients"}}) {
    id
    frontmatter {
      title
      subtitle
      card {
        title
        subtitle
        image
        url
      }
    }
  }
  
   donors: markdownRemark(frontmatter: {url: {eq: "donors"}}) {
    id
    frontmatter {
      title
      subtitle
      card {
        title
        subtitle
        image
        url
      }
    }
  }
  
   employees: markdownRemark(frontmatter: {url: {eq: "employees"}}) {
    id
    frontmatter {
      title
      subtitle
      card {
        title
        subtitle
        image
        url
      }
    }
  }
  }
`
